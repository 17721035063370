<template>
  <div>
    <div class="d-flex">
      <h3 class="ml-1">Doanh thu</h3>
      <div class="position-relative ml-auto">
        <flat-pickr
          style="width: 17rem; background-color: white;"
          v-model="rangeDate"
          class="form-control"
          :config="configFlat"
        >/></flat-pickr>
        <feather-icon
          class="position-absolute"
          style="top: 5; right: 7;"
          size="25"
          icon="CalendarIcon"
        />
      </div>
    </div>
    <b-row>
      <b-col>
        <statistic-card-vertical icon="FileIcon" statistic="78 Đơn thuốc" color="info" />
      </b-col>
      <b-col>
        <statistic-card-vertical icon="DollarSignIcon" statistic="10,234,234 VNĐ" color="success" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BFormGroup
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Vietnamese } from "flatpickr/dist/l10n/vn.js";
import flatpickr from "flatpickr";
import StatisticCardVertical from "@/@core/components/statistics-cards/StatisticCardVertical.vue";

flatpickr.localize(Vietnamese); // default locale is now Russian

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormGroup,
    flatPickr,
    StatisticCardVertical
  },
  data() {
    return {
      rangeDate: "",
      configFlat: {
        mode: "range",
        dateFormat: "d/m/Y",
        defaultDate: ["10/10/2023", "20/10/2023"]
      },
      siteTraffic: {
        series: [
          {
            name: "Traffic Rate",
            data: [150, 200, 125, 225, 200, 250]
          }
        ]
      },
      activeUsers: {
        series: [
          {
            name: "Active Users",
            data: [750, 1000, 900, 1250, 1000, 1200, 1100]
          }
        ]
      },
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "230",
          subtitle: "Đơn thuốc",
          customClass: "mb-2 mb-xl-0"
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "1,769,000 VNĐ",
          subtitle: "Doanh thu",
          customClass: ""
        }
      ]
    };
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
