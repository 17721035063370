<template>
  <div>
    <!-- Head bar -->
    <head-bar :totalWaiting="totalProducts" />
    <!-- Table Container Card -->
    <!-- <h3 class="ml-1">Đơn thuốc chờ xuất</h3>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Hiển thị</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mục</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Tìm kiếm...."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          sticky="100%"
          ref="refProductsTable"
          class="position-relative"
          :items="fetchPharmaProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="Không tìm thấy kết quả nào"
          @row-clicked="handleViewDetail"
        >
          
          <template #cell(prescription_code)="data">
            <span class="text-capitalize">{{`#${data.item.prescription_code}` }}</span>
          </template>
          
          <template #cell(birthday)="data">
            <span class="text-capitalize">{{ formatYear(data.item.person_info) }}</span>
          </template>
          <template #cell(address)="data">
            <span class="text-capitalize">{{ getAddress(data.item) }}</span>
          </template>
          <template #cell(date)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>-->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BFormCheckbox,
  BBadge,
  BPagination,
  BOverlay,
  BCardTitle
} from "bootstrap-vue";
// import TagsListAddNew from '@/components/tagManager/TagsListAddNew.vue'
// import TagsListEdit from '@/components/tagManager/TagsListEdit.vue'
import moment from "moment";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// eslint-disable-next-line import/no-cycle
import useProductsList from "@/views/home/pharmaProduct";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import HeadBar from "@/components/home/HeadBar.vue";
import CardStatisticsGroup from "@/components/home/staticCard/CardStatisticsGroup.vue";
import CardProductInventoryRemain from "@/components/home/staticCard/CardProductInventoryRemain.vue";

export default {
  name: "Home",
  components: {
    BCardTitle,
    HeadBar,
    BOverlay,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
    CardStatisticsGroup,
    CardProductInventoryRemain
  },
  computed: {
    ...mapGetters("app", ["statusOptions"])
  },
  methods: {
    formaterCurrencyToNumber(value) {
      return Number(value.replace(/[^0-9.-]+/g, ""));
    },
    mapBrand(data) {
      if (this.productBrands.find(x => x.value === data)) {
        return this.productBrands.find(x => x.value === data).label;
      } else {
        console.log(data);
        return "None";
      }
    },
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 2000);
    },
    switchIsShow(tag) {
      const payload = tag;
      // eslint-disable-next-line prefer-destructuring
      payload.is_show = !tag.is_show;
      store
        .dispatch("product/updateTags", { id: tag.id, tag: payload })
        .then(() => {
          this.refreshTable();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update show success",
              icon: "AlertTriangleIcon",
              variant: "success"
            }
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error update show",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        });
    },
    showEditBar(data) {
      this.dataTag = {
        name: data.name,
        is_show: {
          label: this.statusOptions.find(e => e.value === data.is_show).label,
          value: data.is_show
        },
        id: data.id
      };
      this.isEditTagsSidebarActive = true;
    },
    formatDate(time) {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    },
    formatYear(time) {
      return time?.birthday ? moment(time?.birthday).format("YYYY") : "";
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this tags.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            store.dispatch("product/deleteTags", { id }).then(() => {
              this.refreshTable();
            });
          }
        });
    },
    handleViewDetail(item) {
      this.$router.replace({
        name: "PrescriptionWaiting",
        params: { id: item.id }
      });
    },
    getAddress(data) {
      let result = "";
      if (data.person_info?.address) {
        result += data.person_info?.address;
      }
      if (data.person_info?.address_district_name) {
        result = result + " " + data.person_info?.address_district_name;
      }
      if (data.person_info?.address_province_name) {
        result = result + " " + data.person_info?.address_province_name;
      }

      return result;
    }
  },

  setup() {
    const dataTag = ref({});
    const {
      timeout,
      showTable,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands
    } = useProductsList();
    return {
      timeout,
      showTable,
      // Sidebar
      dataTag,
      fetchPharmaProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsTable,
      refetchData,
      productBrands,
      // Filter
      avatarText

      // Extra Filters
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
