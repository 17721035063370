<template>
  <div>
    <h3 class="ml-1">Hàng hóa trong kho</h3>
      <b-row>
      <b-col
      >
      <b-link :to="{path:'/product-inventory-manager', query:{statusInventory: 3}}">
        <statistic-card-vertical
          icon="BoxIcon"
          statistic="450"
          statistic-title="Còn hàng"
          color="success"
        />
      </b-link>

      </b-col>
      <b-col

      >
      <b-link :to="{path:'/product-inventory-manager', query:{statusInventory: 2}}">
      <statistic-card-vertical
          icon="BoxIcon"
          statistic="23"
          statistic-title="Sắp hết"
          color="warning"
        />
      </b-link>
      </b-col>
      <b-col

      >
      <b-link :to="{path:'/product-inventory-manager', query:{statusInventory: 1}}">

      <statistic-card-vertical
          icon="BoxIcon"
          statistic="10"
          statistic-title="Đã hết"
          color="danger"
        />
      </b-link>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, 
  BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BFormGroup, BLink
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from "flatpickr/dist/l10n/vn.js"
import flatpickr from "flatpickr"
import StatisticCardWithLineChart from '@/@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import StatisticCardVertical from '@/@core/components/statistics-cards/StatisticCardVertical.vue'
import router from '@/router'

flatpickr.localize(Vietnamese); // default locale is now Russian

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormGroup,
    flatPickr,
    StatisticCardWithLineChart,
    StatisticCardVertical,
    BLink,
  },
  data() {
    return {
      router,
      configFlat: { 
        mode: 'range',
        dateFormat: 'd/m/Y',
        defaultDate: ['10/10/2023', '20/10/2023']
      },
      siteTraffic: {
        series: [
          {
            name: 'Traffic Rate',
            data: [150, 200, 125, 225, 200, 250],
          },
        ],
      },
      activeUsers: {
        series: [
          {
            name: 'Active Users',
            data: [750, 1000, 900, 1250, 1000, 1200, 1100],
          },
        ],
       },
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230',
          subtitle: 'Đơn thuốc',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '1,769,000 VNĐ',
          subtitle: 'Doanh thu',
          customClass: '',
        },
      ],
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
