import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import * as config from "@/utils/config";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProductsList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refProductsTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "prescription_code", label: "Mã Đơn thuốc", sortable: false },
    { key: "person_info.name", label: "Khách hàng", sortable: false },
    { key: "birthday", label: "Năm sinh", sortable: false },
    { key: "address", label: "Địa chỉ", sortable: false },
    { key: "prescribed_doctor", label: "Bác sĩ", sortable: false },
    { key: "date", label: "Ngày", sortable: false },
  ];
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const outOfStockFilter = ref(null);
  const activeFilter = ref(null);
  const productBrands = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const refetchData = () => {
    refProductsTable.value.refresh();
  };
  const fetchBrands = (ctx, callback) => {
    store.dispatch("phamarProductInventory/fetchBrands").then((res) => {
      productBrands.value = res.data.data.map((x) => ({
        label: x.Name,
        value: x.ID,
      }));
    });
  };
  const fetchPharmaProducts = (ctx, callback) => {
    store
      .dispatch("saling/fetchPrescriptions", {
        page: currentPage.value,
        limit: perPage.value,
        is_delivered: 1,
        sort_by: "id",
        order: "desc",
        keyword: searchQuery.value,
      })
      .then((response) => {
        const products = response.data.data || [];
        totalProducts.value = response.data?.page?.total;
        callback(products);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tags list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const timeout = ref(null);
  watch(
    [currentPage, perPage, searchQuery, outOfStockFilter, activeFilter],
    () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 1000);
    }
  );
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchPharmaProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductsTable,
    refetchData,
    productBrands,
    // Extra
  };
}
