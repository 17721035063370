<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <div class="d-flex flex-column justify-content-center">
            <h5 v-if="userLocal">{{ `Welcome ${userLocal.name}!` }}</h5>
            <b-card-text>{{ formatDate(new Date()) }}</b-card-text>
          </div>
        </b-col>
        <!-- <b-col md="4">
          <statistic-card-horizontal
            icon="TruckIcon"
            :statistic="totalWaiting"
            statistic-title="Đơn chờ xuất"
            color="primary"
          />
        </b-col>-->
        <!-- <b-col cols="12" md="4">
          <statistic-card-horizontal
            icon="TrendingUpIcon"
            statistic="100"
            statistic-title="Đơn thuốc đã bán"
            color="primary"
          />
        </b-col>-->
      </b-row>
    </b-card>
    <b-row></b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BCardText
} from "bootstrap-vue";
import vSelect from "vue-select";
import StatisticCardHorizontal from "@/@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    BCardText,
    StatisticCardHorizontal
  },
  props: {
    totalWaiting: Number
  },
  data() {
    return {
      ordersRecevied: {
        series: [
          {
            name: "Orders",
            data: [10, 15, 8, 15, 7, 12, 8]
          }
        ],
        analyticsData: {
          orders: 38400
        }
      }
    };
  },
  computed: {
    userLocal() {
      return JSON.parse(localStorage.getItem("user"));
    }
  },
  methods: {
    formatDate(time) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      return time.toLocaleDateString("vi-VN", options);
    },
    kFormatter(num) {
      return num > 999 ? `${(num / 1000).toFixed(1)}k` : num;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
